@import "src/assets/variables";

.section-hero {
  text-align: center;
  background: $secondary-color;
  padding: 0;
  color: $primary-background-color;

  img {
    width: 100vw;
  }

  .hero-content {
    padding: 2rem 1rem;

    i {
      font-family: $main-font;
    }

    h2 {
      text-transform: none;
      color: $primary-background-color;
    }

    button {
      font-size: .9rem;
    }
  }

  @media (min-width: $medium-size) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 68rem;

    img {
      width: 60vw;
      max-width: 50rem;
      object-fit: cover;
    }

    .hero-content {
      padding: 0 1rem;
      width: 25vw;
      max-width: 25rem;

      button {
        white-space: normal;
        padding: .5rem;
      }
    }
  }
  @media (min-width: $large-size) {
    .hero-content {
      h2 {
        font-size: 2.2rem;
      }
    }
  }
}

