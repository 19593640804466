@import 'src/assets/variables';

.custom-feature {
  padding: 1rem;

  .custom-feature--detail {
    h2 {
      color: $secondary-color;
      font-family: $italic-font;
      text-transform: none;
    }
  }

  @media (min-width: $medium-size) {
    display: flex;
    .custom-feature--detail {
      padding-left: 1rem;
      padding-right: 1rem;
      width: 30rem;
    }
  }
}
